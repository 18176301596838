.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  margin: 10px auto;
}

h2 {
  margin: 50px auto 0px auto;
}

p {
  margin: 10px 20px;
  font-size: 1.2rem;
}

.italics {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.images {
  width: 30%;
  margin: 10px auto;
}

form {
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

input {
  margin: 5px auto;
  border-radius: 5px;
  padding: 15px 30px;
  width: 50%;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
}

::placeholder {
  color: grey;
}

button {
  margin: 5px auto;
}

.btn-primary {
  color: white;
  background-color: #FF0000;
  border-radius: 5px;
  padding: 10px 30px;
  font-weight: 800;
  font-size: 1.4rem;
  width: 53%;
  
}

@media screen and (max-width: 600px)  {
  .images {
    width: 80%;
  }

  h1 {
    margin: 10px auto;
  }
  
  h2 {
    margin: 50px auto 0px auto;
  }

  p {
    margin: 10px 20px;
    font-size: 0.95rem;
  }

  .footer-copyright {
    font-size: 0.75rem;
  }

  form {
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  input {
    margin: 5px auto;
    border-radius: 5px;
    padding: 15px 30px;
    width: 65%;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
  }
  
  ::placeholder {
    color: grey;
  }
  
  button {
    margin: 5px auto;
  }
  
  .btn-primary {
    color: white;
    background-color: #FF0000;
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: 800;
    font-size: 1.2rem;
    width: 80%;
    
  }


}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
